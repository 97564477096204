<template>
    <div>
        <div v-if="preloading">
            <div class="d-flex justify-content-center align-items-center preloading">
                <div class="loader-xl" />
            </div>
        </div>
        <div v-else class="box">
            <router-link to="/projects/new" class="text-decoration-none">
                <b-button icon-left="plus-circle" type="is-warning" class="partenaires-sites-ajout mr-2 mb-2">
                    {{ $t('projets.create') }}
                </b-button>
            </router-link>

            <b-tabs v-model="activeTab">
                <b-tab-item :label="$t('global.active') + ' (' + activeProjects.length + ')'" icon="sitemap">
                    <tab-project
                        :projects="activeProjects"
                        :loading="loading"
                        :disabled="!inactiveProjects.length"
                        @loadAsyncData="loadAsyncData"
                        @onValidateAction="onValidateAction"
                    />
                </b-tab-item>
                <b-tab-item
                    :label="$t('global.inactive') + ' (' + inactiveProjects.length + ')'"
                    icon="power-off"
                    :disabled="!inactiveProjects.length"
                >
                    <tab-project
                        :projects="inactiveProjects"
                        :loading="loading"
                        @loadAsyncData="loadAsyncData"
                        @onValidateAction="onValidateAction"
                    />
                </b-tab-item>
            </b-tabs>
        </div>
        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import TabProject from '@/components/Project/TabProject.vue'
    import Toaster from '@/components/UI/Toaster.vue'

    export default {
        name: 'Projects',
        components: { Toaster, TabProject },
        title: 'projets.title',
        data: function () {
            return {
                preloading: true,
                activeProjects: null,
                inactiveProjects: null,
                activeTab: 0,
                loading: false
            }
        },
        computed: {
            ...mapState('auth', {
                user: 'user'
            }),
            ...mapState('projects', {
                projects: 'projects',
                config: 'projectsConfig'
            })
        },
        created() {
            this.preloading = true
            this.getProjects()
                .then(() => {
                    this.activeProjects = this.projects.filter(project => project.status === true)
                    this.inactiveProjects = this.projects.filter(project => project.status === false)
                })
                .finally(() => {
                    this.preloading = false
                })
        },
        methods: {
            ...mapActions('projects', {
                getProjects: 'listProjects'
            }),
            loadAsyncData() {
                this.loading = true
                this.getProjects()
                    .then(() => {
                        this.activeProjects = this.projects.filter(project => project.status === true)
                        this.inactiveProjects = this.projects.filter(project => project.status === false)
                    })
                    .catch(() => {
                        // TODO revoir le message d'erreur, trop générique
                        this.$refs.toaster.showValidationError({
                            errors: [this.$t('global.error')]
                        })
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            onValidateAction(action, rows) {
                switch (action) {
                    case 'delete':
                        this.deleteProjects(rows)
                        break
                    case 'activate':
                        this.activateProjects(rows)
                        break
                    case 'deactivate':
                        this.deactivateProjects(rows)
                        break
                    case 'edit':
                        this.$router.push('/projects/edit?id=' + rows[0].id)
                        break
                    case 'setDefault':
                        this.setDefaultProject(rows[0].id)
                        break
                }
            },
            deleteProjects: function (projects) {
                if (this.hasDefaultProject(projects)) {
                    this.$refs.toaster.showValidationError({
                        errors: {
                            Erreur: "l'action ne peut être réalisée car l'un des projets sélectionnés est votre projet par défaut."
                        }
                    })
                    return
                }
                let promises = []
                projects.forEach(project => {
                    let promise = this.axios.delete('/crud/projects/' + project.id)
                    promises.push(promise)
                })

                Promise.all(promises)
                    .then(() => {
                        this.$refs.toaster.fire('success', 'Projet(s) supprimé(s)')
                    })
                    .catch(() => {
                        this.$refs.toaster.showValidationError({
                            errors: { Erreur: 'impossible de supprimer' }
                        })
                    })
                    .finally(() => {
                        this.loadAsyncData()
                    })
            },
            deactivateProjects(projects) {
                if (this.hasDefaultProject(projects)) {
                    this.$refs.toaster.showValidationError({
                        errors: {
                            Erreur: "l'action ne peut être réalisée car l'un des projets sélectionnés est votre projet par défaut."
                        }
                    })
                    return
                }

                projects.forEach(project => {
                    if (project.id !== this.user.project_default_id) {
                        project.status = false
                    }
                })

                this.axios
                    .post('/crud/projects', {
                        bulk: 'true',
                        objects: projects
                    })
                    .then(() => {
                        this.$refs.toaster.fire('success', 'Projet(s) désactivé(s)')
                    })
                    .finally(() => {
                        this.loadAsyncData()
                    })
            },
            setDefaultProject(id) {
                let newCurrentUSer = {
                    ...this.user,
                    project_default_id: id
                }
                this.axios
                    .put(`/crud/users/${this.user.id}`, newCurrentUSer)
                    .then(({ data }) => {
                        this.$store.commit('auth/setUser', data.user)
                        this.$refs.toaster.fire('success', 'Nouveau projet par défaut')
                    })
                    .finally(() => {
                        this.loadAsyncData()
                    })
            },
            activateProjects(projects) {
                projects.forEach(project => {
                    project.status = true
                })

                this.axios
                    .post('/crud/projects', {
                        bulk: 'true',
                        objects: projects
                    })
                    .then(() => {
                        this.$refs.toaster.fire('success', 'Projet(s) activé(s)')
                    })
                    .finally(() => {
                        this.loadAsyncData()
                    })
            },
            hasDefaultProject(projects) {
                return projects.some(project => project.id === this.user.project_default_id)
            }
        }
    }
</script>
